/** @jsx jsx */
import {jsx, Box, Flex, Image} from 'theme-ui';
import Animator from "react-reveal/Jello";

const GalleryCard = ({item}) => {
    return (
        <Flex as="figure" sx={styles.figure}>
            <Animator >
                <Image loading="lazy" src={item?.coverImage.base64} alt={item?.name}/>
            </Animator>
        </Flex>
    );
};

export default GalleryCard;

const styles = {
    figure: {
        borderRadius: 5,
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'flex-start',
        mb: '10px',
        mx: '4px',
        width: [
            'calc(33.3333% - 10px)',
            'calc(33.3333% - 10px)',
            'calc(33.3333% - 10px)',
            'calc(25% - 10px)',
            'calc(20% - 10px)',
        ],
        ':before': {
            // background: `linear-gradient(1.15deg, #020718 0.72%, rgba(255, 255, 255, 0) 41.15%)`,
            borderRadius: 5,
            opacity: 0.7,
            content: `''`,
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            zIndex: 1,
        },
        img: {
            borderRadius: 5,
            transform: 'scale(1)',
            transition: 'all 0.3s ease-in-out 0s',
        },
        ':hover': {
            img: {
                transform: 'scale(1.2)',
            },
        },
        figcaption: {
            color: '#fff',
            position: 'absolute',
            fontWeight: 700,
            // fontSize: 18,
            lineHeight: 0.5,
            letterSpacing: 'heading',
            left: 30,
            bottom: 20,
            zIndex: 1,
        },
    },
};
