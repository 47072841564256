/** @jsx jsx */
import {jsx, Box, Container, Text, Button, Heading} from 'theme-ui';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Image from 'components/image';
import {Fade} from "react-reveal";

const Banner = () => {
    const data = useStaticQuery(graphql`
        query {
            illustration: file(relativePath: { eq: "dashboard.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Box as="section" id="dashboard" sx={styles.section}>
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Fade left>
                        <Box as="figure" sx={styles.dashboard}>
                            <Image
                                src={data.illustration.childImageSharp.fluid}
                                width="836"
                                height="458"
                                alt="illustration"
                            />
                        </Box>
                    </Fade>
                    <Fade right>
                        <Box sx={styles.content}>
                            <h1>Quản lý kinh doanh với Dashboard dành cho Đối tác</h1>
                            <Text as="p">Xây dựng một hệ thống quản lý và phát triển doanh thu được dành riêng cho các
                                Đối tác của chúng tôi.
                                Trải nghiệm quản lý và theo dõi các sản phẩm được phân phối trên các nên tảng âm
                                nhạc</Text>
                            <Link style={{textDecoration: 'none'}} to="https://partner.yinyang.vn"><Button>Login</Button></Link>
                        </Box>
                    </Fade>
                </Box>
            </Container>
        </Box>
    );
};

export default Banner;

const styles = {
    section: {
        position: 'relative',
        zIndex: 0,
        pt: [30, null, null, 50, 50, 50],
        pb: [50, 50, 50, 70, 100, 120, 120],
        // backgroundColor: '#fff7f4'
        backgroundColor: 'whitesmock'
    },
    contentWrapper: {
        gap: [12, null, null, 14, 12],
        display: 'grid',
        gridTemplateColumns: ['1fr', null, null, null, '1fr 385px', '1fr 470px'],
        alignItems: 'center',
    },
    heading: {
        textAlign: 'left',
        mb: ['20px'],
        mt: [0, 0, 0, 0, '-70px'],
        h2: {
            fontSize: ['24px', '24px', '24px', '28px', '32px', '40px'],
            lineHeight: [1.45, 1.5],
            letterSpacing: '-1.5px',
        },
    },
    button: {
        display: ['none', 'flex'],
        mt: [45, 45, 45, 25, 25],
    },
    content: {
        maxWidth: [480, null, null, 580, 480],
        mr: [null, null, null, 'auto', 'unset'],
        ml: [0, null, null, 'auto', 10, 8, 10],
        textAlign: [null, null, null, 'left', 'left'],
        h2: {
            fontWeight: 700,
            fontSize: [6, null, null, 10],
            lineHeight: 1.5,
            letterSpacing: 'heading',
        },
        p: {
            fontSize: [1, null, null, 2],
            lineHeight: [1.8, null, null, 2.2],
            mt: [5],
        },
        button: {
            mb: [6, null, null, null, 7, null, 9],
        },
    },
    dashboard: {

    }

};
