/**
 * Spotify player iframe widget
 *
 * @author Alexander Wallin <office@alexanderwallin.com>
 * @see https://developer.spotify.com/technologies/widgets/spotify-play-button/
 */
import React, {Component, useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import {Box, Button, IconButton} from "@material-ui/core";
import {SkipNext, SkipPrevious} from "@material-ui/icons";
// Size presets, defined by Spotify
const sizePresets = {
    large: {
        width: 300,
        height: 380,
    },
    compact: {
        width: 300,
        height: 80,
    },
}

/**
 * MusicPlayer class
 */
const MusicPlayer = (props) => {
    // ------------------------------------------------------
    // Render
    // ------------------------------------------------------
    // const { uri, view, theme } = this.props
    let {url} = props

    return (
        // <iframe src="https://open.spotify.com/embed/track/6AYzbLak1YhYu5litfcTdV" width="100%" height="100%"
        //         frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
        <Box style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', background: 'gray'}}>
            <iframe src={url} width="100%" height="100%"
                    frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>

        </Box>
    )
}

export default MusicPlayer
