import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Clients from 'sections/clients';
import Projects from 'sections/projects';
import Blog from 'sections/blog';
import Contact from "sections/contact";
import Dashboard from "sections/dashboard";
import PlayList from "../sections/playlist";
import Artists from "../sections/artists";
import MessengerCustomerChat from "react-messenger-customer-chat";

export default function IndexPage(props) {
    return (
        <Layout>
            <SEO title="Yin Yang Corporation"/>
            <PlayList/>
            <Dashboard/>
            <Projects/>
            <Artists/>
            <Blog {...props}/>
            <Clients/>
            <Contact/>
            {/*<MessengerCustomerChat
                pageId="107946614866334"
                appId="1386265318396364"
            />*/}
        </Layout>
    );
}
