/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import Slider from 'react-slick';
import SectionHeading from 'components/section-heading';
import BlogPost from 'components/cards/blog-post';
import SlickArrow from 'components/slick-arrow';
import {graphql, useStaticQuery} from "gatsby";

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  nextArrow: <SlickArrow control="next" />,
  prevArrow: <SlickArrow control="prev" />,
  responsive: [
    {
      breakpoint: 100000,
      settings: 'unslick',
    },
    {
      breakpoint: 768,
      settings: {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: 'unslick',
    },
  ],
};

const Blog = () => {

  const data = useStaticQuery(graphql`
    query BlogsQuery {
      admin {
        blogs: findBlogs(criteria: {active: {equals: true}}) {
          id
          slug
          teaser
          thumbnail {
            name
            base64
          }
          title
        }
      }
    }
  `)

  const blogs = data.admin.blogs
  return (
    <Box as="section" id="blog" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          slogan="News"
          title="Những cập nhật mới nhất"
        />
        <Box sx={styles.grid}>
          {blogs?.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Blog;

const styles = {
  section: {
    pt: [12, null, null, 12, 15],
    pb: [20, null, null, 50, 30, 50],
  },
  heading: {
    mb: [20, 20, 20,40, 40, 40],
  },
  grid: {
    display: 'grid',
    gap: [10, null, null, 10, 10, 20],
    alignItems: 'flex-start',
    mx: [null, null, null, -3, 'unset'],
    gridTemplateColumns: ['repeat(1, 1fr)', null, null, null, 'repeat(3, 1fr)'],
    '.slick-arrow': {
      bottom: [null, null, null, -14, 'unset'],
    },
  },
};
