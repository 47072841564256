import zingmp3 from 'assets/images/client-logos/mp3.png';
import titkok from 'assets/images/client-logos/tiktok.png';
import nhaccuatoi from 'assets/images/client-logos/nct.png';
import spotify from 'assets/images/client-logos/spotify.png';
import keeng from 'assets/images/client-logos/keeng.png';
import apple from 'assets/images/client-logos/apple.png';
import vlive from 'assets/images/client-logos/vlive.png';
import tidal from 'assets/images/client-logos/tidal.png';
import facebook from 'assets/images/client-logos/facebook.png';
import googlePlay from 'assets/images/client-logos/google-play.png';
import instagram from 'assets/images/client-logos/instagram.png';
import napster from 'assets/images/client-logos/napster.png';
import pandora from 'assets/images/client-logos/pandora.png';
import amazon from 'assets/images/client-logos/amazon.png';
import deezer from 'assets/images/client-logos/deezer.png';
import youtube from 'assets/images/client-logos/youtube.png';

const images = {
 zingmp3,
 titkok,
 nhaccuatoi,
 spotify,
 keeng,
 apple,
 vlive,
 tidal,
 facebook,
 googlePlay,
 instagram,
 napster,
 pandora,
 amazon,
 deezer,
 youtube,
}

export default images;
