/** @jsx jsx */
import {
    jsx,
    Box,
    Container,
    Heading,
    Text,
    Button,
} from 'theme-ui';
import MusicPlayer from "../components/music-player";
import {graphql, Link, useStaticQuery} from "gatsby";
import {Facebook, Instagram, SkipNext, SkipPrevious} from "@material-ui/icons";
import background from "assets/images/banner-yy.png";
import React, {useState} from "react";

const PlayList = () => {
    const [index, setIndex] = useState(0);

    let goPrevious = () => {
        if (index == 0) {
            setIndex(urls.length - 1)
        } else {
            setIndex(index - 1);
        }
    };
    let goNext = () => {
        if (index == urls.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1);
        }
    }

    const data = useStaticQuery(graphql`
        query ProjectUrlsQuery {
            admin {
                projects: findProjects(criteria: {active: {equals: true}}) {
                    url
                }
            }
        }
    `)

    const urls = data.admin.projects.filter(p => !!p.url).map(p => p.url)
    return (
        <Box sx={styles.section} css={{backgroundImage: `url(${background})`}} id="home" as="section"
             variant="section.banner">
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.content}>
                        <Heading sx={styles.title}>
                            Lan toả âm nhạc của bạn cùng Yin Yang Media
                        </Heading>
                        <Text as="p" sx={styles.text}>
                            Đơn vị đồng hành và hỗ trợ cho các nghệ sĩ Việt Nam trên nền tảng nhạc số trong nước và
                            quốc
                            tế
                        </Text>
                        <Link style={styles.exploreButton}
                              to={'/gioi-thieu-ve-yin-yang-media-1617698836836'}>
                            <Button variant="primary" sx={styles.button}>
                                Explore Now
                            </Button>
                        </Link>
                        <Text sx={styles.subscribeHeader}>Subscribe để cập nhật tin tức nhanh nhất</Text>
                        <Box sx={styles.clients}>
                            <Link target="_blank" to={'https://www.facebook.com/YYMediaCo'}>
                                <Facebook fontSize="large"/>
                            </Link>
                            <Link target="_blank" to={'https://www.instagram.com/yinyang.media'}>
                                <Instagram fontSize="large"/>
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={styles.illustration}>
                        <Box sx={styles.musicPlayerContainer}>
                            <MusicPlayer
                                uri="spotify:user:Bobby:playlist:0sz2J5ZVROxMv3ZkVMTKuw"
                                size="large"
                                theme="black"
                                view="list"
                                url={urls[index]}
                            />

                        </Box>
                        <Box style={{display: 'flex', alignSelf: 'center', marginTop: '10px'}}>
                            <SkipPrevious onClick={goPrevious}></SkipPrevious>
                            <SkipNext onClick={goNext}></SkipNext>
                        </Box>
                    </Box>

                </Box>
            </Container>
        </Box>
    );
};

export default PlayList;

const styles = {
    section: {
        // maxHeight: 714
    },
    contentWrapper: {
        display: [null, null, null, 'grid'],
        gridTemplateColumns: [null, null, null, '0.9fr 1.1fr', 'repeat(2, 1fr)'],
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: [null, null, null, null, null, '90vh'],
        pt: [100, null, null, 120, 130, 0, 0],
    },
    content: {
        maxWidth: [507, 507, 507, 324, 450],
    },
    title: {
        fontWeight: 'bold',
        fontSize: ['30px', null, null, null, '42px', '40px', '60px'],
        lineHeight: 1.33,
        letterSpacing: '-1px',
        color: 'textSecondary',
    },
    text: {
        fontSize: ['14px', '14px', '14px', '16px', '16px', '18px'],
        lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 2.33],
        color: 'textSecondary',
        mt: ['14px', '19px'],
    },
    button: {
        display: ['flex', 'flex'],
        mt: [50, 50, 50, 50, 50],
    },
    clients: {
        display: 'flex',
        alignItems: 'center',
        img: {
            maxWidth: ['80px', '100%', '100%', '100%'],
            '+ img': {
                ml: ['14px', '28px', '28px', '20px'],
            },
        },
        color: '#bac0cb',
        a: {
            color: '#bac0cb',
            decoration: 'none',
            display: 'flex',
            justifyContent: 'center',
        },
        gap: 10
    },
    subscribeHeader: {
        mt: ['20px', '45px', '45px', '30px', '45px'],
        color: "#bac0cb"
    },
    illustration: {
        mt: ['30px', '30px', 0],
        mb: ['60px', '60px', 0],
        img: {
            maxWidth: [null, null, null, null, '90%', '90%', '100%'],
        },
        display: 'flex',
        justifyContent: 'center',
        height: ['100%', "100%", "100%", "100%", "100%", "55%"],
        flexDirection: 'column',
        alignItems: 'center'
    },
    musicPlayerContainer: {
        display: 'flex',
        height: ['350px', '350px', '350px', '100%', '100%', '100%'],
        width: ['100%', "100%", "100%", "70%", "70%", '70%'],
        boxShadow: "5px 5px 15px 5px #000000",
        borderWidth: 7,
        border: '1px solid white'
    },
    exploreButton: {
        textDecoration: 'none'
    }
};
