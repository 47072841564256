/** @jsx jsx */
import {jsx, Box, Button, Container} from 'theme-ui';
// import {Slide} from '@material-ui/core';
import {Slide, Flip} from "react-reveal";

import {BsArrowLeft, BsArrowRight} from 'react-icons/bs';
import Slider from 'react-slick';
import 'assets/css/slick.min.css';
import SectionHeading from 'components/section-heading';
import clientImages from "components/client_images";
import pandora from "../assets/images/client-logos/pandora.png";

const clients = [
    {
        id: 1,
        name: 'Pandora',
        logo: clientImages.pandora,
    },
    {
        id: 2,
        name: 'NhacCuaToi',
        logo: clientImages.nhaccuatoi,
    },

    {
        id: 3,
        name: 'Tiktok',
        logo: clientImages.titkok,
    },
    {
        id: 4,
        name: 'Spotify',
        logo: clientImages.spotify,
    },
    {
        id: 5,
        name: 'Keeng',
        logo: clientImages.keeng,
    },
    {
        id: 6,
        name: 'Apple',
        logo: clientImages.apple,
    },
    {
        id: 7,
        name: 'Vlive',
        logo: clientImages.vlive,
    },
    {
        id: 8,
        name: 'Tidal',
        logo: clientImages.tidal,
    },
    {
        id: 9,
        name: 'Facebook',
        logo: clientImages.facebook,
    },
    {
        id: 10,
        name: 'Google Play',
        logo: clientImages.googlePlay,
    },
    {
        id: 11,
        name: 'Instagram',
        logo: clientImages.instagram,
    },
    {
        id: 12,
        name: 'Napster',
        logo: clientImages.napster,
    },
    {
        id: 14,
        name: 'Amazon',
        logo: clientImages.amazon,
    },
    {
        id: 15,
        name: 'Deezer',
        logo: clientImages.deezer,
    },
    {
        id: 19,
        name: 'Youtube',
        logo: clientImages.youtube,
    }

];

function SlickArrow({className, onClick, control}) {
    return (
        <Button
            variant="text"
            className={className}
            sx={styles.paginationButton}
            onClick={onClick}
        >
            {control === 'prev' ? (
                <BsArrowLeft size="32px"/>
            ) : (
                <BsArrowRight size="32px"/>
            )}
        </Button>
    );
}

const Clients = () => {
        const settings = {
            className: "slider variable-width",
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 350,
            variableWidth: true,
            nextArrow: <SlickArrow control="next"/>,
            prevArrow: <SlickArrow control="prev"/>,

        };

        const settings1 = {
            className: "slider variable-width",
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true
        };

        return (

            <Box id="clients" as="section" sx={styles.section}>
                <Container>
                    <SectionHeading
                        slogan="Partners"
                        title="Hãy để âm nhạc của bạn xuất hiện lên các nền tảng như"
                    />
                    <Box sx={styles.clientContainer}>
                        {clients?.map((client) => (
                            <Flip right>
                                <img sx={styles.logo} src={client.logo}/>
                            </Flip>
                        ))}
                    </Box>
                </Container>
            </Box>
        );
    }
;

export default Clients;

const styles = {
    section: {
        pt: [50, 50, 50, 70, 60, 80],
        pb: [80, 80, 80, 110, 110, 130],
    },

    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
    },
    paginationButton: {
        minHeight: '30px',
        padding: 0,
        position: 'absolute',
        bottom: '-60px',
        ':focus': {
            outline: '0 none',
        },
        svg: {
            transition: 'all 0.2s ease-in-out 0s',
        },
        '&.slick-disabled': {
            color: '#BBC7D7',
            svg: {
                transform: 'scale(0.8)',
            },
        },
        '&.slick-prev': {
            left: 'calc(50% - 16px)',
            transform: 'translateX(-50%)',
        },
        '&.slick-next': {
            transform: 'translateX(50%)',
            right: 'calc(50% - 16px)',
        },
    },
    image: {
        height: '100px',
        // aspectRatio: '1',
        width: 'auto',
        position: 'relative',
        paddingTop: 50,
    },
    clientContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: [10, 10, 10, 50, 50, 50],
        justifyContent: 'center'
    },
    logo: {
        height: '50px',
        width: 'auto !important',
        m: 20
    }
};
