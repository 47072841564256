/** @jsx jsx */
import {jsx, Box, Container, Button} from 'theme-ui';
// import Masonry from 'react-masonry-component';
import SectionHeading from 'components/section-heading';

import {graphql, useStaticQuery} from "gatsby";
import Carousel from "../components/carousel";

import {useState} from "react";

const masonryOptions = {
    transitionDuration: 0,
};

const Projects = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const data = useStaticQuery(graphql`
        query ProjectsQuery {
            admin {
                projects: findProjects(criteria: {active: {equals: true}}) {
                    id
                    coverImage {
                        name
                        base64
                    }
                    name
                    artistName
                }
            }
        }
    `)
    const projects = data.admin.projects;

    const sliders = projects.map((item) => ({key: item.id, content: <img style={{cursor: 'pointer'}} src={item.coverImage.base64} alt={item.id}/>}))
        .map((slide, index) => {
            return {...slide, onClick: () => setSlideIndex(index)};
        });

    return (
        <Box id="projects" as="section" sx={styles.section}>
            <Container sx={styles.container}>
                <SectionHeading
                    sx={styles.heading}
                    slogan="Highlight Projects"
                    title="Những dự án nổi bật"
                />
                <div style={{width: "80%", height: "300px", margin: "0 auto"}}>
                    <Carousel goToSlide={slideIndex} offsetRadius={4}
                              showNavigation={false} slides={sliders}></Carousel>
                </div>

            </Container>
        </Box>
    );
};

export default Projects;

const styles = {
    section: {
        pt: [30, 30, 40, 50, 60],
        pb: [60, 60, 60, 90, 80, 120],
        px: [20, 20, 20, 120, 120, 160],
        backgroundColor: '#f9fafc',

    },
    heading: {
        mb: [30, 30, 40, 60],
    },
    galleryWrapper: {
        mx: '-15px',
    },
    button: {
        minHeight: [50, 50, 50, 60],
        fontSize: [14, 14, 16],
        width: '50%',
        svg: {
            transition: 'margin-left 0.3s ease-in-out 0s',
        },
        ':hover': {
            svg: {
                ml: '5px',
            },
        },
    },
};
