/** @jsx jsx */
import { jsx, Box, Container, Button } from 'theme-ui';
import Masonry from 'react-masonry-component';
import SectionHeading from 'components/section-heading';
import GalleryCard from 'components/cards/gallery-card';

import {graphql, useStaticQuery} from "gatsby";

const masonryOptions = {
    transitionDuration: 0,
};

const Artists = () => {
    const data = useStaticQuery(graphql`
        query ArtistsQuery {
            admin {
                artists: findArtists(criteria: {active: {equals: true}}) {
                    id
                    coverImage {
                        name
                        base64
                    }
                    name
                }
            }
        }
    `)
    const artists = data.admin.artists;

    return (
        <Box id="projects" as="section" sx={styles.section}>
            <Container sx={styles.container}>
                <SectionHeading
                    sx={styles.heading}
                    slogan="Artists"
                    title="Nghệ sĩ Tiêu Biểu"
                />
                <Box as={Masonry} options={masonryOptions} sx={styles.galleryWrapper}>
                    {artists?.map((item) => (
                        <GalleryCard key={item.id} item={item} />
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default Artists;

const styles = {
    section: {
        pt: [30, 30, 40, 50, 60],
        pb: [60, 60, 60, 90, 80, 120],
        px: [20, 20, 20, 120, 120, 160]
    },
    heading: {
        mb: [30, 30, 40, 60],
    },
    galleryWrapper: {
        mx: '-15px',
    },
    button: {
        minHeight: [50, 50, 50, 60],
        fontSize: [14, 14, 16],
        width: '100%',
        svg: {
            transition: 'margin-left 0.3s ease-in-out 0s',
        },
        ':hover': {
            svg: {
                ml: '5px',
            },
        },
    },
};
