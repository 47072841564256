/** @jsx jsx */
import { jsx, Box, Flex, Image, Heading, Text } from 'theme-ui';
import { Link, LearnMore } from 'components/link';

const BlogPost = ({ post }) => {
  return (
    <Box as="article" sx={styles.post}>
      <Flex as="figure">
        <Image style={{width: '100%'}} src={post?.thumbnail.base64} loading="lazy" alt={post?.thumbnail.name} />
      </Flex>

      <Box className="content">
        <span sx={styles.title}>
          <Link path={post?.slug}>{post?.title}</Link>
        </span>
      </Box>
    </Box>
  );
};

export default BlogPost;

const styles = {
      post: {
        m: [0, 0, 0, '0 15px', 0],
        mx: [20, 50, 20, 20, 20],
        figure: {
          alignItems: 'flex-start',
          mb: 20,
        },
        h4: {
          fontWeight: 500,
          fontSize: [14, 16, 20],
          lineHeight: 1.5,
          a: {
            cursor: 'pointer',
            color: 'text',
            textDecoration: 'none',
          },
        },
      },
  image: {
    height: '100%',
    width: 'auto',
    aspectRatio: 1
  },
  thumbnail: {
    height: 300,
    mb: [4],
    img: {
      borderRadius: 5,
    },
  },
  title: {
    fontWeight: 1000,
    fontSize: "17px",
    lineHeight: [null, null, null, null, null, 1.68],
    letterSpacing: '-0.2px',
    a: {
      color: '#999999',
      textDecoration: 'none',
    },
  },
  excerpt: {
    color: '#999999',
    fontSize: "15px",
    lineHeight: 1.88,
    mt: [2],
  },

};
